<template>
  <column-picker
    :selected-col-id.sync="computedCol"
    :filters="filters"
    :label="$t(`t.Selector`)"
    :attach="false"
    :show-icon="true"
    sum-cols-only
    currency-disabled
    consolidation-disabled
  />
</template>

<script>
export default {
  components: {
    ColumnPicker: () => import('@/components/column-picker')
  },
  computed: {
    computedCol: {
      get () { return this.dataCol },
      set (v) {
        this.dataCol = v
        this.dataDocumentEmit()
      }
    },
    filters () {
      return { 'document-types': [[`${this.targetDocTy}`], []] }
    }
  },
  methods: {
    emitDocument () {
      const doc = { type: 'colId', col: this.dataCol }
      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      dataCol: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    value: Object,
    targetDocTy: String
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataCol = v?.col ? this.lodash.cloneDeep(v.col) : null
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
